import React from "react"
import PropTypes from "prop-types"
import "./BlogFeature.scss"
import Button from "components/elements/styledButton"
import { useGetStaffMemberSlugPrefix } from "services/graphQl/queries/careNoteSlugPrefix"
import Link from "components/elements/link"

const BlogFeature = ({ cards, careNote, title }) => {
  const slugPrefix = useGetStaffMemberSlugPrefix()

  return (
    <section className="blog-feature">
      <div className="blog-feature__grid">
        <Link to={`/${slugPrefix}/${careNote.slug}`}>
          <div className="blog-feature__main">
            {careNote?.icon?.file?.url ? (
              <img
                alt={careNote.title || "Blog Image"}
                className="blog-feature__image"
                src={careNote.icon.file.url}
              />
            ) : (
              <div className="blog-feature__image-placeholder">No Image</div>
            )}
            <div className="blog-feature__text-overlay">
              <h2>{careNote?.title || "Untitled Blog"}</h2>
              <p className="blog-feature__category">
                {careNote?.category?.name || "Uncategorized"}
              </p>
              <p>
                {careNote?.description?.description ||
                  "No description available."}
              </p>
            </div>
          </div>
        </Link>
        <div className="blog-feature__sidebar">
          {cards?.length > 0 ? (
            cards.map((card, index) => (
              <div className="blog-feature__card" key={index}>
                {card.icon?.file?.url && (
                  <img
                    alt={card.title || "Card Icon"}
                    className="blog-feature__card-icon"
                    src={card.icon.file.url}
                  />
                )}
                <p>{card.description?.description || "No Description"}</p>
                {card.action && (
                  <Button
                    {...card.action.action}
                    className="text-lockup__button"
                  />
                )}
              </div>
            ))
          ) : (
            <p className="blog-feature__no-cards">
              No related cards available.
            </p>
          )}
        </div>
      </div>
    </section>
  )
}

BlogFeature.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
      icon: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      action: PropTypes.shape({
        action: PropTypes.shape({
          url: PropTypes.string,
          openInANewTab: PropTypes.bool,
        }),
      }),
    })
  ),
  careNote: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.shape({
      description: PropTypes.string,
    }),
    icon: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    category: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
}

BlogFeature.defaultProps = {
  cards: [],
  careNote: null,
  title: "Blog Feature",
}

export default BlogFeature
