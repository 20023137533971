import { useStaticQuery, graphql } from "gatsby"

export const useGetStaffMemberSlugPrefix = () => {
  const query = useStaticQuery(
    graphql`
      query NoteSlugPrefix {
        template: contentfulWebPageTemplate(model: { eq: "Care Note" }) {
          slugPrefix
        }
      }
    `
  )
  return query.template?.slugPrefix || ""
}
