import React from "react"
import PropTypes from "prop-types"
import Link from "components/elements/link"
import "./categoryCard.scss"

const CategoryCard = ({
  category,
  categoryKey,
  ctaText,
  description,
  icon,
  name,
  slug,
  style,
  title,
}) => {
  switch (categoryKey) {
    case "learn":
      return (
        <Link to={slug}>
          <div id="CategoryCard">
            {icon && icon.file && (
              <div className="card-image-container">
                <img alt={title || "Card image"} src={icon.file.url} />
              </div>
            )}
            {title && <h4 className="title">{title}</h4>}
            {name && <p className="title">{name}</p>}
            {category && (
              <p className="categoryName" style={{ color: category?.theme }}>
                {category?.name}
              </p>
            )}
            {description && (
              <p className="content">{description?.description}</p>
            )}
            {ctaText && <button className="primaryButton">{ctaText}</button>}
          </div>
        </Link>
      )
    case "treatment":
      return (
        <div className="title">
          <div
            className="treatment"
            id="CategoryCard"
            style={{
              borderTop: `5px solid ${style || category?.theme}`,
            }}
          >
            {title && (
              <div className="title" to={slug}>
                {title}
              </div>
            )}
            {name && (
              <div className="title" to={slug}>
                {name}
              </div>
            )}
            {category && <p className="categoryName">{category?.name}</p>}
            {description && (
              <p className="content">{description?.description}</p>
            )}
            {ctaText && (
              <Link className="primaryButton" to={slug}>
                {ctaText}
              </Link>
            )}
          </div>
        </div>
      )

    default:
      return null
  }
}

export const CategoryCardProps = {
  category: PropTypes.shape({
    name: PropTypes.string,
    theme: PropTypes.string,
  }),
  categoryKey: PropTypes.string,
  cta: PropTypes.string,
  ctaText: PropTypes.string,
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  icon: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  name: PropTypes.string,
  slug: PropTypes.string,
  style: PropTypes.string,
  title: PropTypes.string,
}

CategoryCard.propTypes = CategoryCardProps

export default CategoryCard
