import React from "react"
import PropTypes from "prop-types"
import "./unOrderList.scss"
import All_Img from "../../../../static/images/All_img.svg"
const UnOrderList = ({
  activeCategory,
  data = [],
  handleClick,
  listStyle = "none",
}) => (
  <ul>
    <div className="list__grid" style={{ listStyle: listStyle }}>
      <div
        className={`list__card`}
        onClick={() => handleClick("All")}
        onKeyDown={() => handleClick("All")}
        role="presentation"
      >
        <img alt={"all"} className="list__icon" src={All_Img} />
        <p
          className={`list__text  ${activeCategory === "All" ? "active" : ""}`}
        >
          {"All"}
        </p>
      </div>
      {data.map((feature, index) => (
        <div
          className={`list__card`}
          key={index}
          onClick={() => handleClick(feature.id)}
          onKeyDown={() => handleClick(feature.id)}
          role="presentation"
        >
          {feature.stickerMedia?.image?.file?.url && (
            <img
              alt={feature.stickerMedia.image.alt || ""}
              className="list__icon"
              src={feature.stickerMedia.image.file.url}
            />
          )}
          <p
            className={`list__text ${
              activeCategory === feature.id ? "active" : ""
            }`}
          >
            {feature.name}
          </p>
        </div>
      ))}
    </div>
  </ul>
)

UnOrderList.propTypes = {
  activeCategory: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  handleClick: PropTypes.func,
  listStyle: PropTypes.string,
}

export default UnOrderList
