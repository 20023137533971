import { useStaticQuery, graphql } from "gatsby"

export const useStaticQueryGetCareNotesCategorList = () => {
  const query = useStaticQuery(
    graphql`
      query CareNotesList {
        allContentfulCareNote(sort: { order: DESC, fields: createdAt }) {
          nodes {
            createdAt
            id
            slug
            title
            description {
              description
            }
            icon {
              file {
                url
              }
              title
              description
            }
            category {
              id
              name
              theme
            }
          }
        }
      }
    `
  )
  return query.allContentfulCareNote.nodes
}
