import React from "react"
import PropTypes from "prop-types"
import SECTIONS from "utils/sectionConstants"
import SectionList, { SectionListProps } from "components/sections/sectionList"
import Wrapper, { WrapperProps } from "components/sections/wrapper"
import PageHeader, { PageHeaderProps } from "components/sections/pageHeader"
import DynamicSection, {
  DynamicSectionProps,
} from "components/sections/dynamicSection"
import TextSection, { TextSectionProps } from "components/sections/textSection"
import RichText, { RichTextProps } from "components/elements/richText"
import SideBySide, { SideBySideProps } from "components/sections/sideBySide"
import ActionsList, { ActionsListProps } from "components/sections/actionsList"
import SectionCards, {
  SectionCardsProps,
} from "components/sections/sectionCards"
import HeroVideo, { HeroVideoProps } from "components/sections/heroVideo"
import HeroImage, { HeroImageProps } from "components/sections/heroImage"
import QuoteSection, {
  QuoteSectionProps,
} from "components/sections/quoteSection"
import OrderedList, { OrderedListProps } from "components/blocks/orderedList"
import ReferralPageDetails from "views/referralDetails"
import SplitSection from "components/sections/splitSection"
import COMPONENTS from "utils/componentConstants"
import Faq from "components/sections/faq"
import BlogFeature from "components/sections/BlogFeature"
const WebpageTemplate = ({ sections }) =>
  sections.map((section, i) => {
    switch (section.__typename) {
      case SECTIONS.Subheader:
        return <PageHeader {...section} key={i} />

      case SECTIONS.SectionListOfActions:
        return <ActionsList {...section} key={i} />

      case SECTIONS.ContentfulSectionRichText:
        return <RichText {...section} key={i} />

      case SECTIONS.HeroVideo:
        return <HeroVideo data={section} key={i} />

      case SECTIONS.TextSection:
        return <TextSection {...section} key={i} />

      case SECTIONS.DynamicSection: {
        const featuredArticlesSection = sections?.find(
          sec => sec.__typename === SECTIONS.FeaturedArticles
        )

        const featuredArticles = featuredArticlesSection
          ? featuredArticlesSection.featuredArticles
          : []

        return (
          <DynamicSection
            data={section}
            featuredArticles={featuredArticles}
            key={i}
          />
        )
      }
      case SECTIONS.SectionList:
        return <SectionList {...section} key={i} />

      case SECTIONS.HighlightedBlog:
        return <BlogFeature {...section} key={i} />

      case SECTIONS.SideBySide:
        return <SideBySide {...section} key={i} />

      case SECTIONS.SectionCards:
        return <SectionCards {...section} key={i} />

      case SECTIONS.SectionHeroPhoto:
        return <HeroImage {...section} key={i} />

      case SECTIONS.SectionQuote:
        return <QuoteSection {...section} key={i} />

      case SECTIONS.WrapperSection:
        return <Wrapper {...section} key={i} />

      case SECTIONS.OrderedList:
        return <OrderedList {...section} key={i} />

      case SECTIONS.TemplateDetailsSection:
        return <ReferralPageDetails {...section} key={i} />

      case COMPONENTS.SplitContent:
        return <SplitSection {...section} key={i} />

      case COMPONENTS.Faq:
        return <Faq {...section} key={i} />

      default:
        return null
    }
  })

WebpageTemplate.props = {
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(ActionsListProps),
      PropTypes.shape(DynamicSectionProps),
      PropTypes.shape(HeroImageProps),
      PropTypes.shape(HeroVideoProps),
      PropTypes.shape(OrderedListProps),
      PropTypes.shape(PageHeaderProps),
      PropTypes.shape(QuoteSectionProps),
      PropTypes.shape(RichTextProps),
      PropTypes.shape(SectionCardsProps),
      PropTypes.shape(SectionListProps),
      PropTypes.shape(SideBySideProps),
      PropTypes.shape(TextSectionProps),
      PropTypes.shape(WrapperProps),
    ])
  ),
  slug: PropTypes.string,
  title: PropTypes.string,
}

WebpageTemplate.propTypes = WebpageTemplate.props

export default WebpageTemplate
