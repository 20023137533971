import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQueryGetNoteCategory } from "services/graphQl/queries/careNotesCategories"
import { useStaticQueryGetCareNotesCategorList } from "services/graphQl/queries/careNotesList"
import { useGetStaffMemberSlugPrefix } from "services/graphQl/queries/careNoteSlugPrefix"
import HELPERS from "utils/helpers"
import UnOrderList from "components/blocks/unOrderList"
import CategoryCard from "components/blocks/categoryCard"
import "./learn.scss"

const LearnPage = ({ featuredArticles }) => {
  const careNotesCategories = useStaticQueryGetNoteCategory()
  const slugPrefix = useGetStaffMemberSlugPrefix()
  const [activeCategory, setActiveCategory] = useState("All")
  const careNotesList = useStaticQueryGetCareNotesCategorList().map(note => ({
    ...note,
    slug: `/${slugPrefix}/${note.slug}`,
  }))
  const featuredArticlesList = featuredArticles.map(note => ({
    ...note,
    slug: `/${slugPrefix}/${note.slug}`,
  }))
  const [selectedCategoryList, setSelectedCategoryList] =
    useState(careNotesList)
  const activeCategoryName =
    activeCategory === "All"
      ? "All Articles"
      : careNotesCategories.find(category => category.id === activeCategory)
          ?.name || "All Articles"
  const handleClick = e => {
    setActiveCategory(e)
    setSelectedCategoryList(
      e !== "All"
        ? careNotesList.filter(item => item.category?.id === e)
        : careNotesList
    )
  }

  useEffect(() => {
    const params = HELPERS.getUrlParameters(window.location.search)

    if (params.category) {
      setSelectedCategoryList(
        careNotesList?.filter(item => item.category?.id === params.category)
      )
    }
  }, [careNotesList])

  return (
    <div id="Learn">
      <div className="Categories-wrapper">
        <div className="container">
          <div className="row display-flex justify-content-center">
            <div className="col-12">
              <UnOrderList
                activeCategory={activeCategory}
                data={careNotesCategories}
                handleClick={e => handleClick(e)}
              />
            </div>
          </div>
        </div>
      </div>
      {featuredArticlesList && activeCategory === "All" && (
        <div id="featured-articles">
          <div className="container">
            <h2 className="category-heading">Featured Articles</h2>
            <div className="featured-articles__grid">
              {featuredArticlesList?.map(card => (
                <div className="featured-articles__card" key={card?.id}>
                  <CategoryCard {...card} categoryKey="learn" />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div id="content">
        <div className="container">
          <h2 className="category-heading">{activeCategoryName}</h2>
          <div className="row">
            {selectedCategoryList?.map(card => (
              <div className="col-md-4 my-3" key={card?.id}>
                <CategoryCard {...card} categoryKey="learn" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
LearnPage.propTypes = {
  featuredArticles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
      icon: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
      category: PropTypes.shape({
        name: PropTypes.string.isRequired,
        theme: PropTypes.string,
      }),
    })
  ),
}

export default LearnPage
