import { useStaticQuery, graphql } from "gatsby"

export const useStaticQueryGetNoteCategory = () => {
  const query = useStaticQuery(
    graphql`
      query NoteCategory {
        allContentfulNoteCategory {
          nodes {
            id
            name
            theme
            stickerMedia {
              image {
                alt: description
                title
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  )
  return query.allContentfulNoteCategory.nodes
}
